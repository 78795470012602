<template>
	<v-dialog persistent max-width="764px" ref="passwordDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title class="pl-4 pr-4">
					<span class="text-h5 mb-3">Change Password</span>
				</v-card-title>
				<v-card-text class="pl-4 pr-4">
					<v-row>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<v-text-field dense type="password" label="Password*" minlength="8" maxlength="16" :rules="rules.password" :error-messages="errors.password" v-model="object.password"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<v-text-field dense type="password" label="Confirm Password*" minlength="8" maxlength="16" :rules="rules.confirm_password" :error-messages="errors.confirm_password" v-model="object.confirm_password"></v-text-field>
						</v-col>
					</v-row>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { encodeBase64 } from '@/utils/encodes'

export default {
	name: 'AccountPasswordDialog',
    props: {
        strict: {
            type: Boolean,
            required: false,
            default: false
        }
    },
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
            object: {
                type: '',
                password: '',
                confirm_password: ''
            },
			rules: {
                password: [
                    (value) => {
                        if(!value) {
                            return 'Password is required'
                        }

                        if(!/^.{8,16}$/.test(value)) {
                            return 'Password must 8 to 16 characters'
                        }

                        if(this.strict) {
                            if(!/^(?=.*[A-Z]).*$/.test(value)) {
                                return 'Password must contain at least 1 upper case character'
                            }

                            if(!/^(?=.*[a-z]).*$/.test(value)) {
                                return 'Password must contain at least 1 lower case character'
                            }

                            if(!/^(?=.*\W)/.test(value)) {
                                return 'Password must contain at least 1 symbol character'
                            }
                        }

                        return true
                    },
                ],
                confirm_password: [
                    (value) => {
                        if(!value) {
                            return 'Confirm password is required'
                        }

                        if(!/^.{8,16}$/.test(value)) {
                            return 'Password must 8 to 16 characters'
                        }

                        if(this.strict) {
                            if(!/^(?=.*[A-Z]).*$/.test(value)) {
                                return 'Password must contain at least 1 upper case character'
                            }

                            if(!/^(?=.*[a-z]).*$/.test(value)) {
                                return 'Password must contain at least 1 lower case character'
                            }

                            if(!/^(?=.*\W)/.test(value)) {
                                return 'Password must contain at least 1 symbol character'
                            }
                        }

                        if(this.object.password && this.object.password !== value) {
                            return 'Confirm password does not match'
                        }

                        return true
                    }
                ],
			},
			errors: {}
		}
	},
	computed: {
		passwordDialog() {
			return this.$refs.passwordDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.object = { password: '', confirm_password: '', type: '', id: '' }
			this.errors = {}
			this.form.resetValidation()
		},
        updateObject: function(item) {
            this.object = item
        },
		save: function() {
			if(this.object.type && this.form.validate()){
				this.loading = true
				const object = { id: this.object.id, object: { password: encodeBase64(this.object.password), confirm_password: encodeBase64(this.object.confirm_password) }}
                this.$store.dispatch(this.getDispatchName(), object).then((response) => {
                    this.close()
                    this.$emit('updated', { message: response.message })
                }).catch(() => {
                    this.loading = false
                })
			}
		},
        getDispatchName: function() {
            if(this.object.type === 'admin'){
                return 'epanel/admin/updateAdminPassword'
            }else if(this.object.type == 'promoter'){
                return 'epanel/promoter/updatePromoterPassword'
            }
        }
	}
}

</script>