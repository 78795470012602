<template>
	<v-dialog persistent max-width="764px" ref="updateDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title class="pl-4 pr-4">
					<span class="text-h5 mb-3">Update Account</span>
				</v-card-title>
				<v-card-text class="pl-4 pr-4">
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<display-label label="Username" :text="object.username"></display-label>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Email*" :rules="rules.email" :error-messages="errors.email" v-model="object.email"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="First Name*" :rules="rules.first_name" :error-messages="errors.first_name" v-model="object.first_name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Last Name*" :rules="rules.last_name" :error-messages="errors.last_name" v-model="object.last_name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Mobile Number*" :rules="rules.mobile_number" :error-messages="errors.mobile_number" v-model="object.mobile_number"></v-text-field>
						</v-col>
					</v-row>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'
import DisplayLabel from '../../../shared/DisplayLabel.vue'

export default {
	name: 'AdminUpdateDialog',
	components: {
        displayLabel: DisplayLabel,
	},
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                username: '',
				first_name: '',
				middle_name: '',
				last_name: '',
				email: '',
				mobile_number: ''
			},
			rules: {
				first_name: [
					(value) => !!value || 'First name is required',
					(value) => value.length >= 1 && value.length <= 100 || 'First name must be between 1 and 100 characters'
				],
				last_name: [
					(value) => !!value || 'Last name is required',
					(value) => value.length >= 1 && value.length <= 100 || 'Last name must be between 1 and 100 characters'
				],
				email: [
					(value) => !!value || 'Email is required',
					(value) => /.+@.+\..+/.test(value) || 'Email is invalid'
				],
                mobile_number: [
                    (value) => !!value || 'Mobile number is required',
                    (value) => value && value.length === 8 || 'Mobile number must be 8 digits',
                    (value) => value && new RegExp("^[0-9]+$").test(value) || 'Mobile number must be numeric value'
                ],
			},
			errors: {}
		}
	},
	computed: {
		updateDialog() {
			return this.$refs.updateDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.object = { username: '', first_name: '', last_name: '', email: '', mobile_number: '', }
			this.errors = {}
			this.form.resetValidation()
		},
        updateObject: function(item) {
            this.object = item
        },
		save: function() {
			if(this.form.validate()){
				this.loading = true
                var object = copyObject(this.object, ['id', 'username'])
				this.$store.dispatch('epanel/admin/updateAdmin', { id: this.object.id, object: object }).then((response) => {
                    object = response.data.object
                    this.close()
                    this.$emit('updated', { object: response.data.object, message: response.message })
                }).catch(() => {
                    this.loading = false
                })
			}
		}
	}
}

</script>