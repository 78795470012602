<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5 mb-3">Create Account</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Username*" :rules="rules.username" :error-messages="errors.username" v-model="object.username"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Email*" :rules="rules.email" :error-messages="errors.email" v-model="object.email"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="First Name*" :rules="rules.first_name" :error-messages="errors.first_name" v-model="object.first_name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Last Name*" :rules="rules.last_name" :error-messages="errors.last_name" v-model="object.last_name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense type="password" label="Password*" minlength="8" maxlength="16" :rules="rules.password" :error-messages="errors.password" v-model="object.password"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense type="password" label="Confirm Password*" minlength="8" maxlength="16" :rules="rules.confirm_password" :error-messages="errors.confirm_password" v-model="object.confirm_password"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Mobile Number*" :rules="rules.mobile_number" :error-messages="errors.mobile_number" v-model="object.mobile_number"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-select dense label="Role*" :items="roles" :rules="rules.role" :error-messages="errors.role" v-model="object.role"></v-select>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-select dense label="Status*" :items="statuses" :rules="rules.status" :error-messages="errors.status" v-model="object.status"></v-select>
						</v-col>
					</v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small><br>
                            <small class="red--text">Note: Once role is confirmed, it cannot be changed.</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="create">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { encodeBase64 } from '@/utils/encodes'
import { copyObject } from '@/utils/helper'

export default {
	name: 'AdminCreateDialog',
	props: {
        roles:  {
            type: Array,
            required: false,
            default: () => []
        },
        statuses: {
            type: Array,
            required: false,
            default: () => []
        }
	},
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                username: '',
				first_name: '',
				last_name: '',
				email: '',
				password: '',
				confirm_password: '',
				role: '',
				status: ''
			},
			rules: {
				username: [
					(value) => !!value || 'Username is required',
					(value) => value.length >= 3 && value.length <= 30 || 'Username must be between 3 and 30 characters'
				],
				first_name: [
					(value) => !!value || 'First name is required',
					(value) => value.length >= 1 && value.length <= 100 || 'First name must be between 1 and 100 characters'
				],
				last_name: [
					(value) => !!value || 'Last name is required',
					(value) => value.length >= 1 && value.length <= 100 || 'Last name must be between 1 and 100 characters'
				],
				email: [
					(value) => !!value || 'Email is required',
					(value) => /.+@.+\..+/.test(value) || 'Email is invalid'
				],
				password: [
                    (value) => {
                        if(!value) {
                            return 'Password is required'
                        }

                        if(!/^.{8,16}$/.test(value)) {
                            return 'Password must 8 to 16 characters'
                        }

                        if(!/^(?=.*[A-Z]).*$/.test(value)) {
                            return 'Password must contain at least 1 upper case character'
                        }

                        if(!/^(?=.*[a-z]).*$/.test(value)) {
                            return 'Password must contain at least 1 lower case character'
                        }

                        if(!/^(?=.*\W)/.test(value)) {
                            return 'Password must contain at least 1 symbol character'
                        }

                        return true
                    },
				],
				confirm_password: [
                    (value) => {
                        if(!value) {
                            return 'Confirm password is required'
                        }

                        if(!/^.{8,16}$/.test(value)) {
                            return 'Password must 8 to 16 characters'
                        }

                        if(!/^(?=.*[A-Z]).*$/.test(value)) {
                            return 'Password must contain at least 1 upper case character'
                        }

                        if(!/^(?=.*[a-z]).*$/.test(value)) {
                            return 'Password must contain at least 1 lower case character'
                        }

                        if(!/^(?=.*\W)/.test(value)) {
                            return 'Password must contain at least 1 symbol character'
                        }

                        if(this.object.password && this.object.password !== value) {
                            return 'Confirm password does not match'
                        }

                        return true
                    }
				],
				status: [
					(value) => !!value || 'Status is required'
				],
				role: [
					(value) => !!value || 'Role is required'
				],
                mobile_number: [
                    (value) => !!value || 'Mobile number is required',
                    (value) => value && value.length === 8 || 'Mobile number must be 8 digits',
                    (value) => value && new RegExp("^[0-9]+$").test(value) || 'Mobile number must be numeric value'
                ],
			},
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.form.resetValidation()
			this.object = { username: '', first_name: '', last_name: '', email: '', password: '', confirm_password: '',
                            mobile_number: '', role: '', status: '' }
			this.errors = {}
		},
		create: function() {
			if(this.form.validate()){
				this.loading = true

				var object = copyObject(this.object, ['username', 'password', 'confirm_password'])
				object['username'] = encodeBase64(this.object.username)
				object['password'] = encodeBase64(this.object.password)
				object['confirm_password'] = encodeBase64(this.object.confirm_password)

				this.$store.dispatch('epanel/admin/createAdmin', object).then((response) => {
                    this.close()
                    this.$emit('created', { message: response.message })
                }).catch(() => {
                    this.loading = false
                })
			}
		}
	}
}

</script>