<template>
	<div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Admin Management</p>
                <p class="text-title mb-0">You do not have permission to view all admin accounts</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field dense clearable label="Email" v-model="filters.email"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field dense clearable label="Name" v-model="filters.name"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-if="roles.length > 0">
                                <v-select dense clearable label="Role" :items="roles" :menu-props="{top: false, offsetY: true}" v-model="filters.role"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-if="statuses.length > 0">
                                <v-select dense clearable label="Status" :items="statuses" :menu-props="{top: false, offsetY: true}" v-model="filters.status"></v-select>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="permissions.can_add">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='openCreateDialog'>
                        <v-icon small left>mdi-plus</v-icon> Create Account
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-tabs grow color="primary" v-model="tab" @change="changeTab">
                        <v-tab :key="index" v-text="item.text" v-for="(item, index) in statuses"></v-tab>
                    </v-tabs>
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.username"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.first_name"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.last_name"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.email"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.mobile_number"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.role"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.last_login"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.status"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y v-if="hasMenu(item)">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon :ripple="false" v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="openUpdateDialog(index, item)" v-if="item.can_edit">
                                                <v-list-item-title>Edit Account</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openPasswordDialog(item)" v-if="item.can_edit_password">
                                                <v-list-item-title>Change Password</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item :to="getPermissionUrl(item.id)" v-if="item.can_view_permissions">
                                                <v-list-item-title>Manage Permissions</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openStatusDialog(index, item, 1)" v-if="!item.is_locked && item.can_lock">
                                                <v-list-item-title>Lock Account</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openStatusDialog(index, item, 2)" v-if="item.is_locked && item.can_lock">
                                                <v-list-item-title>Activate Account</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row v-if="!loading && pagination.total_pages > 1">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
                </v-col>
            </v-row>
        </template>
		<create-dialog ref="createDialog" :roles="roles" :statuses="statuses" @created="updateItems"></create-dialog>
		<update-dialog ref="updateDialog" @updated="updateItem"></update-dialog>
		<password-dialog strict ref="passwordDialog" @updated="updateItem"></password-dialog>
		<status-dialog ref="statusDialog" @updated="updateItem"></status-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import MessageNotifier from '../../../shared/MessageNotifier.vue'
import AdminCreateDialog from './AdminCreateDialog.vue'
import AdminUpdateDialog from './AdminUpdateDialog.vue'
import AdminStatusDialog from './AdminStatusDialog.vue'
import AccountPasswordDialog from '../AccountPasswordDialog.vue'

export default {
	name: 'AdminList',
	components: {
        messageNotifier: MessageNotifier,
		createDialog: AdminCreateDialog,
		updateDialog: AdminUpdateDialog,
		statusDialog: AdminStatusDialog,
		passwordDialog: AccountPasswordDialog,
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
            tab: null,
			filters: {
				name: '',
				email: '',
				role: '',
				status: '',
				page: 1
			},
            permissions: {
                can_list: false,
                can_add: false,
            },
			roles: [],
			statuses: [],
			items: [],
			headers: [],
			pagination: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		updateDialog() {
			return this.$refs.updateDialog
		},
		passwordDialog() {
			return this.$refs.passwordDialog
		},
		statusDialog() {
			return this.$refs.statusDialog
		},
        notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/admin/getAdmins', this.filters).then((response) => {
				this.items = response.data.items
				this.headers = response.data.headers
				this.pagination = response.data.pagination
				this.roles = response.data.roles
				this.statuses = response.data.statuses
				this.permissions = response.data.permissions
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { name: '', email: '', role: '', status: '', page: 1}
		},
        hasMenu: function(item) {
            return item.can_edit || item.can_edit_password || item.can_lock || item.can_view_permissions
        },
        openCreateDialog: function() {
            this.createDialog.open()
        },
        openUpdateDialog: function(index, item) {
            if(item.can_edit){
                this.selectedIndex = index
                const object = { id: item.id, username: item.username, first_name: item.first_name, last_name: item.last_name,
                                 mobile_number: item.mobile_number, email: item.email }
                this.updateDialog.updateObject(object)
                this.updateDialog.open()
            }
        },
        openPasswordDialog: function(item) {
            if(item.can_edit_password){
                this.passwordDialog.updateObject({ id: item.id, type: 'admin' })
                this.passwordDialog.open()
            }
        },
        openStatusDialog: function(index, item, status) {
            if(item.can_lock){
                this.selectedIndex = index
                this.statusDialog.updateObject({ id: item.id, status: status })
                this.statusDialog.open()
            }
        },
		updateItems: function(item) {
            this.reset()
            this.get()
            this.openNotifier(item.message)
		},
		updateItem: function(item) {
            if(item.object){
                this.$set(this.items, this.selectedIndex, item.object)
            }
            this.openNotifier(item.message)
		},
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        getPermissionUrl: function(id) {
            return 'admin/' + id + '/permission'
        },
        changeTab: function() {
            this.filters.status = this.statuses[this.tab].value
            this.get()
        },
	}
}

</script>